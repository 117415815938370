import * as React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import sponsor1 from "../../images/pages/who-we-are/sponsor1.png"
import sponsor2 from "../../images/pages/who-we-are/sponsor2.png"
import sponsor3 from "../../images/pages/who-we-are/sponsor3.png"

import Seo from "../components/seo"

const MemberItem = ({ data }) => {
  const {
    featuredImage: {
      node: { sourceUrl },
    },
    coalitionFields: {
      noPadding,
      siteLink: { url },
    },
  } = data
  return (
    <a href={url} target="_blank">
      <img
        className={`pic ${noPadding !== null ? "no-padding" : ""}`}
        src={sourceUrl}
      />
    </a>
  )
}

const GET_MEMBERS = graphql`
  {
    allWpCoalitionMember {
      edges {
        node {
          featuredImage {
            node {
              sourceUrl
            }
          }
          coalitionFields {
            noPadding
            siteLink {
              url
            }
          }
        }
      }
    }
  }
`

const WhoWeArePage = () => {
  const data = useStaticQuery(GET_MEMBERS)
  const { edges } = data.allWpCoalitionMember
  console.log(edges)
  return (
    <section id="who-we-are">
      <Seo
        title="Who We Are"
        description="Parents, teachers, students, neighbors and community leaders who believe all students deserve equal access to a quality education."
      />
      <div className="one big-text-center">
        <div className="container">
          <div className="content">
            <p>
              The Good4U coalition is made up of individuals and organizations.
              Parents, teachers, students, concerned neighbors, community
              activists, local leaders and more who believe even one dropout is
              too many when you consider the lost potential and negative impact
              on communities.
            </p>
          </div>
        </div>
      </div>
      <div className="two">
        <div className="container">
          <div className="content">
            <h2>Coalition Members</h2>
            <div className="top">
              {edges.map(item => {
                return <MemberItem data={item.node} />
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhoWeArePage
